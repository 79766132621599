import {
  ApolloClient,
  ApolloClientOptions,
  ApolloLink,
  from,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client';
import { GraphQLError } from 'graphql';

import { getMeta } from '@/helpers/get_meta';

const errorHandler = new ApolloLink((operation, forward) => {
  return forward(operation).map((response) => {
    const error = response?.data?.data?.error;

    if (error)
      throw new GraphQLError(error.message, {
        extensions: { code: error.code, details: (error.details && error.details[0]) || '' },
      });

    return response;
  });
});

const httpLink = new HttpLink({
  uri: '/graphql',
  headers: {
    'X-ClientToken': getMeta('api-client-token'),
    'X-SessionToken': getMeta('api-session-token'),
  },
});

const baseSettings: ApolloClientOptions<NormalizedCacheObject> = {
  link: from([errorHandler, httpLink]),
  cache: new InMemoryCache({
    typePolicies: {
      PopupRestaurant: {
        keyFields: ['id', 'serviceStartTime', 'serviceEndTime'],
      },
      CafeRestaurant: {
        keyFields: ['vendorId'],
      },
    },
  }),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
    },
    query: {
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
    },
  },
};

export const getClient = (options?: Omit<ApolloClientOptions<NormalizedCacheObject>, 'cache'>) =>
  new ApolloClient({ ...baseSettings, ...options });
